import React from "react"

import InstagramSVG from "../svg/instagram.svg"
import FacebookSVG from "../svg/facebook.svg"

export default function Social(props) {
  return (
    <ul className="Social">
      <li>
        <a
          target="_blank"
          rel="noopener"
          href="https://instagram.com/2ndfloorevents"
        >
          <InstagramSVG />
        </a>
      </li>
      <li>
        <a
          target="_blank"
          rel="noopener"
          href="https://facebook.com/2ndfloorevents"
        >
          <FacebookSVG />
        </a>
      </li>
    </ul>
  )
}
