import React from "react"

import { Link } from "gatsby"

export default function Nav(props) {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/features" onClick={props.toggleNav}>
            Features
          </Link>
        </li>
        <li>
          <Link to="/weddings" onClick={props.toggleNav}>
            Weddings
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={props.toggleNav}>
            About
          </Link>
        </li>
        <li>
          <Link to="/food-drink" onClick={props.toggleNav}>
            Food + Drink
          </Link>
        </li>
        <li>
          <Link to="/health-safety" onClick={props.toggleNav}>
            Health + Safety
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={props.toggleNav}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  )
}
